import { useUserSession } from "@/session/UserSession";
import { ApiKey, ApiKeyTypes, PrincipalType, User } from "@vertesia/common";
import { Avatar, Divider, Table } from "@reactik/components";
import { useFetch } from "@reactik/hooks";
import { ReactNode } from "react";
import Popover from "./popover/Popover";


//TODO use a real cache
const USER_CACHE: Record<string, Promise<User>> = {};

/**
 * Fetch the user information given a user reference.
 * The reference has the format: `type:id`. A special reference `system` is used to refer to the system user.
 * @param userRef
 */
export function useFetchUserInfo(userId: string) {
    const { client } = useUserSession();

    return useFetch(() => {
        let user: Promise<User> | undefined = USER_CACHE[userId];
        if (!user) {
            console.log("Fetching user info", userId);
            user = client.users.retrieve(userId).then(user => {
                return user;
            });
            USER_CACHE[userId] = user;
        }
        return user;
    }, [userId]);
}


function AvatarPlaceholder() {
    return <div className='h-8 w-8' />
}

function SystemAvatar() {
    return (
        <UserPopoverPanel title="System User" description="The system user is used to intiialize built-in objects.">
            <Avatar src="/icon.svg" />
        </UserPopoverPanel>
    )
}


function ServiceAcountAvatar({ accountId }: { accountId: string }) {
    return (
        <UserPopoverPanel title="Service Account" description={
            <>
                <div>This user is used by robots like workflow workers.</div>
                <div className="text-gray-800 text-sm"><span className="font-semibold">ID:</span> {accountId}</div>
            </>
        }>
            <Avatar src="/cloud.svg" name="SA" color="bg-amber-500" className="px-[5px] text-white" />
        </UserPopoverPanel>
    );
}

function ErrorAvatar({ title = "Error", error }: { title?: string, error: Error | string }) {
    return (
        <UnknownAvatar title={title} message={typeof error === 'string' ? error : error.message} color="bg-red-500" />
    );
}

interface UserInfoProps {
    userRef: string | undefined;
}
export function UserInfo({ userRef }: UserInfoProps) {
    if (!userRef) return <UnknownAvatar title="Unknown User" message="User information is not available." />
    const [type, id] = userRef ? userRef.split(':') : ["unknown"];
    switch (type) {
        case PrincipalType.User: return <UserAvatar userId={id} />
        case "system": return <SystemAvatar />
        case PrincipalType.ServiceAcount: return <ServiceAcountAvatar accountId={id} />
        case PrincipalType.ApiKey: return <ApiKeyAvatar keyId={id} />
        default: return <ErrorAvatar title="Unknown User" error={`Invalid user ref type: ${type}`} />
    }
}

interface UnknownAvatarProps {
    title: string;
    message: ReactNode;
    color?: string;
}
function UnknownAvatar({ title, message, color }: UnknownAvatarProps) {
    return (
        <UserPopoverPanel title={title} description={message}>
            <Avatar color={color} />
        </UserPopoverPanel>
    )
}

interface UserAvatarProps {
    userId: string;
}
function UserAvatar({ userId }: UserAvatarProps) {
    const { data: user, isLoading, error } = useFetchUserInfo(userId);
    if (error) {
        return <ErrorAvatar title="Failed to fetch user" error={error} />
    }
    return user ? (
        <UserPopoverPanel title={user.name || user.email || user.username || "unknown"} description={user.email}>
            <Avatar src={user.picture} name={user.name} color="bg-indigo-500" />
        </UserPopoverPanel>
    ) : <AvatarPlaceholder />
}

interface ApiKeyAvatarProps {
    keyId: string
}
export function ApiKeyAvatar({ keyId }: ApiKeyAvatarProps) {
    const { client } = useUserSession();
    const { data, error } = useFetch<ApiKey>(() => client.apikeys.retrieve(keyId), []);
    if (error) return <ErrorAvatar title="Falied to fetch the apikey" error={error} />
    if (!data) return <AvatarPlaceholder />
    const isPublic = data.type === ApiKeyTypes.public;
    const title = isPublic ? "Public Key" : "Private Key";
    const avatar = <Avatar name={isPublic ? "PK" : "SK"} color="bg-pink-500" />
    const description = <Table>
        <tr>
            <td className="font-semibold">Key:</td>
            <td>{data?.name}</td>
        </tr>
        <tr>
            <td className="font-semibold">Account:</td>
            <td>{data?.account}</td>
        </tr>
        <tr>
            <td className="font-semibold">Project:</td>
            <td>{data?.project}</td>
        </tr>
    </Table>;
    return (
        <UserPopoverPanel title={title} description={description}>
            {avatar}
        </UserPopoverPanel>
    )
}



interface UserPopoverPanelProps {
    title: string;
    description: ReactNode;
    children: React.ReactNode;
}
export function UserPopoverPanel({ title, description, children }: UserPopoverPanelProps) {
    return (
        <Popover strategy='fixed' placement='right-start' zIndex={100}>
            <Popover.Trigger click className="cursor-pointer flex items-center">
                {children}
            </Popover.Trigger>
            <Popover.Content>
                <div className="bg-indigo-50 rounded-md shadow-md py-2">
                    <div className='px-4 pb-2 text-lg font-semibold'>{title}</div>
                    <Divider className="bg-indigo-100" />
                    <div className='px-4 pt-4 pb-2'>{description}</div>
                </div>
            </Popover.Content>
        </Popover>
    )
}
