import { ReactNode, useEffect, useMemo, useState } from 'react';

import { useUserSession } from '@/session/UserSession';
import { Button, Modal, ModalBody, ModalTitle, SelectBox } from '@reactik/components';
import { ContentObjectTypeLayout } from '@vertesia/common';

interface SelectObjectTypeModalProps {
    title?: string;
    children?: ReactNode;
    actionLabel: string;
    isOpen: boolean;
    onClose: (typeId?: string | null | undefined) => void;
}
export default function SelectObjectTypeModal({ isOpen, onClose, title = "Select Content Type", actionLabel, children }: SelectObjectTypeModalProps) {
    return (
        <Modal onClose={() => onClose(undefined)} isOpen={isOpen} className="relative overflow-visible">
            <ModalTitle>{title}</ModalTitle>
            <SelectPanel onClose={onClose} actionLabel={actionLabel}>{children}</SelectPanel>
        </Modal>
    );
}

interface SelectPanelProps {
    onClose: (typeId?: string | null) => void;
    children?: ReactNode;
    actionLabel: string;
}
function SelectPanel({ onClose, children, actionLabel }: SelectPanelProps) {
    const session = useUserSession();
    const { typeRegistry } = session;
    const [selectedType, setSelectedType] = useState<ContentObjectTypeLayout | undefined>(undefined);

    useEffect(() => {
        session.reloadTypes();
    }, [session]);

    const types = useMemo(() => {
        return typeRegistry?.types || [] as ContentObjectTypeLayout[];
    }, [typeRegistry?.types]);

    const onSubmit = () => {
        onClose(selectedType?.id ?? null);
    };

    const optionLabel = (t: ContentObjectTypeLayout | null) => {
        if (t === null) return 'None';

        return (
            <div>
                <div className="text-sm">{t.name}</div>
                <div className="text-xs text-gray-500">{t.description}</div>
            </div>
        );
    };

    return (
        <ModalBody className="pt-0">
            <div className='mb-2'>{children}</div>
            <div className='h-full flex flex-col gap-4 content-between'>
                <SelectBox options={types} value={selectedType} onChange={setSelectedType}
                    placeholder="Choose a Content Type..."
                    optionLabel={optionLabel}
                    className="h-full w-full text-sm"
                    filterBy="name"
                    isClearable
                />

                <Button className="w-full" isDisabled={!selectedType} onClick={onSubmit}>Use the Selected Content Type</Button>

                <div className="flex w-full items-center gap-x-2">
                    <div className="w-full bg-slate-500 h-1"></div>
                    <div className="font-semibold">or</div>
                    <div className="w-full bg-slate-500 h-1"></div>
                </div>

                <Button className="w-full" isDisabled={selectedType !== undefined} onClick={onSubmit}>Let Vertesia Choose for Me</Button>
            </div>
        </ModalBody>
    );
}
