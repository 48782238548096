import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { UserInfo } from '@/components/UserInfo';
import ConfirmActionButton from '@/modules/store/features/store/objects/ConfirmActionButton';
import { SelectEnvironment, SelectModel } from '@/modules/studio/components/SelectEnvironment';
import ProjectNamespaceWidget from '@/modules/studio/features/project/ProjectNamespaceWidget';
import { useUserSession } from '@/session/UserSession';
import { EmbeddingsStatusResponse, ExecutionEnvironmentRef, Project, SupportedEmbeddingTypes } from '@vertesia/common';
import { AIModel } from '@llumiverse/core';
import { Button, Input, useToast } from '@reactik/components';
import { useFetch } from '@reactik/hooks';

interface ProjectSettingsProps { }

function DataField({ label, value }: { label: string, value?: string; }) {
    return (
        <div className="flex flex-col py-2">
            <div className="font-semibold text-sm">{label}</div>
            <div className="text-md">{value ?? 'N/A'}</div>
        </div>
    );
}

export default function ProjectConfigurationView({ }: ProjectSettingsProps) {

    const { client, account, project } = useUserSession();
    const [isSaving, setIsSaving] = useState(false);
    const toast = useToast();
    const [projectName, setProjectName] = useState<{ name?: string, namespace?: string } | undefined>(undefined);
    const [canSaveName, setCanSaveName] = useState(false);
    const [isNsValid, setIsNsValid] = useState(true);

    const { data: projectData, setData: setProjectData, refetch } = useFetch(() => {
        if (!project) return Promise.resolve(undefined);
        return client.projects.retrieve(project.id).then((project) => {
            setProjectName({ name: project.name, namespace: project.namespace });
            return project;
        });
    }, [client, account]);

    const setProjectNamespace = (namespace: string) => {
        if (!projectName) return;
        setProjectName({ ...projectName, namespace });
    }

    const saveProjectNames = async () => {
        if (!projectName || !project) return;
        if (!isNsValid) {
            toast({
                title: "Invalid Namespace",
                description: "Namespace must be unique, at least 3 characters long, and can only contain alphanumeric characters, and [-_]",
                status: "error",
                duration: 2000
            });
            return;
        }
        setIsSaving(true);
        await client.projects.update(project?.id, projectName).then(() => {
            toast({
                title: "Project Saved",
                description: "Project settings have been saved successfully",
                status: "success",
                duration: 1000
            })
            refetch();
        }).finally(() => {
            setIsSaving(false);
        });
    }

    const saveProject = async () => {
        if (!projectData || !project) return;
        setIsSaving(true);
        await client.projects.update(project?.id, projectData).then(() => {
            toast({
                title: "Project Saved",
                description: "Project settings have been saved successfully",
                status: "success",
                duration: 1000
            })
            refetch();
        }).finally(() => {
            setIsSaving(false);
        });
    }

    useEffect(() => {
        if (projectData && !projectData.configuration) {
            setProjectData({
                ...projectData,
                configuration: {
                    human_context: "",
                    default_environment: undefined,
                    default_model: undefined,
                    embeddings: {
                    }
                }

            });
        }
    }, [projectData]);

    useEffect(() => {
        if (projectName?.name && projectName?.namespace && projectData) {
            setCanSaveName(projectData.name !== projectName.name || projectData.namespace !== projectName.namespace);
        }
    }, [projectName, projectData]);

    const updateGenerativeEnvironment = (env?: ExecutionEnvironmentRef) => {
        if (projectData && env) {
            setProjectData({
                ...projectData,
                configuration: { ...projectData.configuration, default_environment: env.id }
            });
        }
    }

    const updateModel = (model?: AIModel) => {
        if (projectData && model) {
            setProjectData({
                ...projectData,
                configuration: { ...projectData.configuration, default_model: model.id }
            });
        }
    }

    const updateProjectContext = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (projectData) {
            setProjectData({
                ...projectData,
                configuration: { ...projectData.configuration, human_context: e.target.value }
            });
        }
    }

    return (
        projectData &&
        <div className="w-full">
            <div>
                <h2 className="font-semibold text-lg">Project ID</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm py-2">Name</label>
                        <Input className="w-full" value={projectName?.name} onChange={(e) => setProjectName({ ...projectName, name: e })} />
                    </div>
                    <div className='flex flex-1 gap-2 items-end justify-end text-xs'>
                        <UserInfo userRef={projectData.created_by} />
                        <DataField label="Created at" value={dayjs(projectData.created_at).format("YYYY-MM-DD HH:mm")} />
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <ProjectNamespaceWidget onChange={setProjectNamespace} namespace={projectName?.namespace} setIsValid={setIsNsValid} />
                    </div>
                    <div className='flex flex-1 gap-2 items-center justify-end text-xs'>
                        <UserInfo userRef={projectData.updated_by} />
                        <DataField label="Updated at" value={dayjs(projectData.updated_at).format("YYYY-MM-DD HH:mm")} />
                    </div>
                </div>
                <div className="flex flex-col w-full items-end pt-4">
                    <ConfirmActionButton
                        isDisabled={!canSaveName || !isNsValid}
                        confirmationText="Are you sure you want to change the name or namespace? changing the namespace will affect all execute interaction by name."
                        doAction={saveProjectNames}>Save</ConfirmActionButton>
                </div>
            </div>

            <div className="pt-4">
                <h2 className="font-semibold text-lg">Project Context</h2>
                <p className="text-sm py-2">Explain the project context, background, and objectives.
                    This will be used to pass more context to the model, to guide it and achieve better results.</p>
                <textarea className="rounded w-full h-48 text-md dark:bg-slate-700" onChange={updateProjectContext} value={projectData?.configuration?.human_context ?? ""} />
            </div>

            <div className="pt-4">
                <h2 className="font-semibold text-lg">Default Environment for Generation</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm py-2">Environment</label>
                        <SelectEnvironment isClearable onChange={updateGenerativeEnvironment} selectedEnvId={projectData?.configuration?.default_environment} />
                    </div>
                    <div>
                        <label className="block text-sm py-2">Model</label>
                        {projectData.configuration?.default_environment &&
                            <SelectModel isClearable envId={projectData.configuration.default_environment} selectedModelId={projectData?.configuration?.default_model} onChange={updateModel} />
                        }
                    </div>

                </div>
                <div className="flex flex-col w-full items-end pt-4">
                    <Button size={"lg"} isLoading={isSaving} onClick={saveProject}>Save</Button>
                </div>
            </div>

            <h2 className="font-semibold text-lg py-2">Embeddings Generation</h2>
            <EmbeddingsConfiguration projectData={projectData} refetch={refetch} type={SupportedEmbeddingTypes.text} />
            <EmbeddingsConfiguration projectData={projectData} refetch={refetch} type={SupportedEmbeddingTypes.properties} />
            <EmbeddingsConfiguration projectData={projectData} refetch={refetch} type={SupportedEmbeddingTypes.image} />

        </div>
    );
}

interface EmbeddingsConfigurationParams {
    projectData?: Project;
    type: SupportedEmbeddingTypes
    refetch: () => void;
}

function EmbeddingsConfiguration({ projectData, type, refetch }: EmbeddingsConfigurationParams) {
    const { client, store } = useUserSession();
    const toast = useToast();
    const config = projectData?.configuration;
    const embConfig = config?.embeddings[type]
    const isEnabled = embConfig?.enabled;

    const [selectedEnv, setSelectedEnv] = useState<string | undefined>(embConfig?.environment);
    const [selectedModel, setSelectedModel] = useState<string | undefined>(embConfig?.model);
    const [maxTokens, setMaxTokens] = useState<string | undefined>(embConfig?.max_tokens?.toString());
    const [isWorking, setIsWorking] = useState(false);
    const [status, setStatus] = useState<EmbeddingsStatusResponse | undefined>(undefined);

    useEffect(() => {
        onRefresh().then(_r => {
            if (projectData) {
                setSelectedEnv(embConfig?.environment);
                setSelectedModel(embConfig?.model);
                setMaxTokens(embConfig?.toString());
            }
        });
    }, [projectData]);

    const onRefresh = () => {
        setIsWorking(true);
        return store.commands.embeddings.status(type).then((status) => {
            setStatus(status);
        }).catch((e) => {
            toast({
                title: "Cannot get status",
                description: e.message,
                status: "error",
                duration: 2000
            });
        }).finally(() => {
            setIsWorking(false);
        });
    }

    const onEnvironmentChange = (env?: ExecutionEnvironmentRef) => {
        setSelectedEnv(env?.id);
    }

    const onActivate = () => {
        if (!selectedEnv) {
            toast({
                title: "Error: no environment selected",
                description: "Please select an environment to activate embeddings generation",
                status: "error",
                duration: 2000
            });
            return;

        }
        setIsWorking(true);

        store.commands.embeddings.activate(type, {
            environment: selectedEnv,
            model: selectedModel,
            max_tokens: maxTokens ? parseInt(maxTokens) : undefined
        }).catch((e) => {
            toast({
                title: "Error activating embeddings generation",
                description: e.message,
                status: "error",
                duration: 5000
            });
        }).then(() => {
            toast({
                title: "Embeddings Generation Activated",
                description: "Embeddings generation has been activated successfully",
                status: "success",
                duration: 2000
            });
        }).finally(() => {
            refetch();
            setIsWorking(false);
        });
    }

    const onDisable = () => {
        setIsWorking(true);
        store.commands.embeddings.disable(type).then(() => {
            toast({
                title: "Embeddings Generation Deactivated",
                description: "Embeddings generation has been deactivated successfully",
                status: "success",
                duration: 2000
            });
            setSelectedEnv(undefined);
        }).catch((e) => {
            toast({
                title: "Error",
                description: e.message,
                status: "error",
                duration: 2000
            });
        }).finally(() => {
            refetch();
            setIsWorking(false);
        });
    }

    const onRecalculate = () => {
        setIsWorking(true);
        store.commands.embeddings.recalculate(type).then(() => {
            toast({
                title: "Embeddings reindex launched",
                description: "Embeddings reindex has been launched successfully",
                status: "success",
                duration: 2000
            });
        }).catch((e) => {
            toast({
                title: "Error: cannot launchn embeddings reindex",
                description: e.message,
                status: "error",
                duration: 2000
            });
        }).finally(() => {
            refetch();
            setIsWorking(false);
        });
    }

    const onSave = () => {
        setIsWorking(true);
        if (!projectData) return;

        client.projects.update(projectData.id, {
            ...projectData,
            configuration: {
                ...projectData.configuration,
                embeddings: {
                    ...projectData.configuration.embeddings,
                    environment: selectedEnv,
                    model: selectedModel,
                    max_tokens: maxTokens ? parseInt(maxTokens) : undefined,
                } as any
            }
        }).then(() => {
            toast({
                title: "Project Saved",
                description: "Project settings have been saved successfully",
                status: "success",
                duration: 1000
            });
        }).finally(() => {
            refetch();
            setIsWorking(false);
        });
    };

    const indexReady = status?.vectorIndex.status === "READY";
    const canActivate = status?.vectorIndex.status === "ABSENT" || undefined || !embConfig?.environment;
    const shouldSave = maxTokens !== embConfig?.max_tokens?.toString() || selectedModel !== embConfig?.model || selectedEnv !== embConfig?.environment;

    return (
        <div>
            <h3 className="font-semibold text-lg py-2">{type.toLocaleUpperCase()}</h3>
            {!isEnabled && <p className="text-sm">
                Embeddings generation is disabled. Enable it to configure the embeddings generation settings.
            </p>}
            {isEnabled && <p className="text-sm">
                Configure the embeddings generation settings for the project. Warning: changing the embedding model to a different model will require to re-generate the embeddings for all objects.
            </p>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                <div>
                    <label className="block text-sm py-2">Environment</label>
                    <SelectEnvironment disabled={isEnabled} isClearable onChange={onEnvironmentChange} selectedEnvId={selectedEnv} />
                </div>
                <div>
                    <label className="block text-sm py-2">Model <span>(only change if default doesn't apply)</span></label>
                    <Input name="modelId" value={selectedModel} onChange={setSelectedModel} placeholder="Set the model Id if you don't want to use the default" />
                </div>
            </div>
            <div className="flex gap-4 pt-2">
                <div className="w-full">
                    <label className="block text-sm py-2">Max Tokens <span>(only change if default doesn't apply)</span></label>
                    <Input name="maxToken" value={maxTokens} onChange={setMaxTokens} type="number" placeholder="Set the max number of tokens the model can handle" />
                </div>
                <div className="w-full">
                    <label className="block text-sm py-2">Dimensions</label>
                    <Input name="Dimensions" disabled readOnly value={embConfig?.dimensions?.toString() ?? "N/A"} />
                </div>
            </div>
            <div className="flex pt-4 w-full gap-x-4 justify-end">
                {!isEnabled && <Button size="lg" onClick={onActivate} isLoading={isWorking} isDisabled={!canActivate}>Activate</Button>}
                {isEnabled &&
                    <Button className="bg-red-600" size="lg" isLoading={isWorking} isDisabled={!indexReady} onClick={onDisable}>Disable</Button>
                }
                {isEnabled &&
                    <Button size="lg" isLoading={isWorking} isDisabled={!shouldSave} onClick={onSave}>Save</Button>
                }
            </div>
            <h3 className="font-semibold text-md my-4">Status</h3>
            <div className="flex justify-between gap-4 mb-4">
                <pre className="bg-red rounded bg-slate-200 dark:bg-slate-700 px-4 py-2 text-sm" >{JSON.stringify(status, null, 2)}</pre>
                <div className="flex flex-col gap-4 items-end ">
                    <Button isLoading={isWorking} onClick={onRefresh}>Refresh Status</Button>
                    <Button isLoading={isWorking} isDisabled={!indexReady} className="bg-red-600" onClick={onRecalculate}>Recalculate Embeddings</Button>
                </div>
            </div>
        </div>
    );

}
